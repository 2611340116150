// @flow
import React from 'react';
import Layout from '../../components/layout';
import Link from '../../components/link';
import GlossaryLink from '../../components/glossary-link';
import { withFrontMatter } from '../../components';
import type { FrontMatter } from '../../utils/types';
import CanyonRatingCard from '../../components/canyon-rating-card';

class Blog_2012_04_28_My_Story extends React.Component<{
  frontMatter: FrontMatter,
}> {
  render() {
    const { frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter} toc>
        <p>
          In the years leading up to 2012, I had gotten married, finished
          school, got a steady job, bought a house, and we were expecting our
          first child. I've heard this referred to as "settling down". I was at
          a point in my life where things were pretty constant. Consequently, I
          was trying lots of new sports and activities but hadn't really found
          anything I <em>loved</em>.
        </p>
        <h2>My first canyoneering trip</h2>
        <p>
          A friend from work invited me to go canyoneering in the{' '}
          <Link to="http://ropewiki.com/San_Rafael_Swell">
            San Rafael Swell
          </Link>{' '}
          one weekend. I had seen the reef from a distance and loved it and
          thought it would be cool to visit so I agreed.
        </p>
        <p>
          As I was leaving work the night before our trip and the <em>hour</em>{' '}
          I was supposed to head down, my friend asked if I had a{' '}
          <GlossaryLink>harness</GlossaryLink> and{' '}
          <GlossaryLink>rappel device</GlossaryLink>. I told him I had my
          climbing harness and <GlossaryLink>ATC</GlossaryLink>. He asked if I
          had clothes that could get dirty and good sturdy shoes. I told him I
          had my gym clothes I was planning on wearing and my running shoes
          should work. I was ready! Or so I thought.
        </p>
        <p>
          He then asked if I had a <GlossaryLink>wetsuit</GlossaryLink>,{' '}
          <GlossaryLink id="helmet">climbing helmet</GlossaryLink>,{' '}
          <GlossaryLink>backpack</GlossaryLink> that could get destroyed,{' '}
          <GlossaryLink>rope</GlossaryLink>,{' '}
          <GlossaryLink>dry bag</GlossaryLink>, neoprene socks, gloves, knee and
          elbow pads, etc. Nope, nope, nope, nope - time for a quick shopping
          trip!
        </p>
        <p>
          He took me to this weird store called{' '}
          <Link to="https://outnback.com/">Out N Back</Link> and asked how much
          money I was willing to spend. I quickly realized that if I was going
          to get into this sport I would have to spend a lot of money. If I
          wasn't there was no point in spending any money. It was all in or all
          out and I had to make the decision right then.
        </p>
        <p>
          "I'm all in, let's do this." - I probably said something really corny.
          My friend went to work. He picked out a{' '}
          <GlossaryLink id="wetsuit">4/3 full wetsuit</GlossaryLink>,{' '}
          <Link to="http://www.shop.outnback.com/imlay-spry-hybrid-pack/">
            Imlay Spry backpack
          </Link>
          , neo socks,{' '}
          <Link to="https://www.rei.com/product/866952/la-sportiva-xplorer-approach-shoes-mens">
            La Sportiva Xplorers
          </Link>
          , and everything else. I arrived home to an upset wife and we headed
          down.
        </p>
        <h2>Zero Gravity</h2>
        <CanyonRatingCard rating="3BIIR" title="Zero Gravity" />
        <p>
          We headed out to Zero Gravity Canyon the next morning. It's a short
          slot but packs a lot of beauty within. It starts off with a couple of{' '}
          <GlossaryLink>natural bridges</GlossaryLink>, then some{' '}
          <GlossaryLink>down-climbs</GlossaryLink> and skinny swimming{' '}
          <GlossaryLink>hallways</GlossaryLink> with a{' '}
          <GlossaryLink id="semi-keeper-pothole">semi-keeper</GlossaryLink> in
          the middle. It ends with a spectacular{' '}
          <GlossaryLink>bomb bay</GlossaryLink>.
        </p>
        <p>
          I thoroughly enjoyed it. I was moving really slow because I didn't
          know how to down climb very well and my group moved on ahead of me.
          Consequently I had the canyon to myself so I felt comfortable
          vocalizing my wonder my thoughts. It was the driest I have ever seen
          it and wetsuits were definitely not necessary but there were still a
          couple of swims.
        </p>
        <p>
          Even though the technical section is less than 1/4 mile, the twists
          and contours of the <GlossaryLink>Navajo sandstone</GlossaryLink>, the{' '}
          <em>so skinny but passable</em> width, the down climbing into pools,
          all of if hooked me immediately. I was obsessed.
        </p>
        <h2>I need training</h2>
        <p>
          After that canyoneering trip I was hooked. The problem I was now
          facing was I had been exposed to an amazing world but didn't know
          anything and didn't know anybody. My friend was more of a
          one-canyon-trip-a-year type of person. I spent the next 4 months
          trying to find people to go with. My dad had tipped me off to a group
          of scout leaders who were also canyoneering instructors. I probably
          should not have been allowed to join that meetup group (more on that
          later), but I weaseled my way in and signed up for the first trip that
          became available. It was a couple of{' '}
          <GlossaryLink>trade canyons</GlossaryLink> in{' '}
          <Link to="http://ropewiki.com/Zion_National_Park">
            Zion National Park
          </Link>
          . I ended going with these guys through{' '}
          <Link to="http://ropewiki.com/Keyhole_Canyon_(Zion_National_Park)">
            Keyhole
          </Link>{' '}
          the first day and{' '}
          <Link to="http://ropewiki.com/Behunin_Canyon">Behunin</Link> the
          second.
        </p>
        <CanyonRatingCard rating="3BII" title="Keyhole" />
        <CanyonRatingCard rating="3BIII" title="Behunin" />
        <p>Some of the questions and answer I gave were:</p>
        <ul>
          <li>
            What is that thing you are doing instead of a{' '}
            <GlossaryLink>biner block</GlossaryLink>? They were rigging a{' '}
            <GlossaryLink>contingency 8</GlossaryLink>
          </li>
          <li>
            What canyons had I done? Trying to sound more experienced than I
            was, I said Zero Gravity and "a bunch more in the Swell".
            Technically true since I had visited{' '}
            <Link to="http://ropewiki.com/Little_Wild_Horse_Canyon">
              Little Wild Horse
            </Link>
            , <Link to="http://ropewiki.com/Bell_Canyon">Bell</Link>, and{' '}
            <Link to="http://ropewiki.com/Straight%20Wash%20(Lower)">
              Lower Straight Wash
            </Link>{' '}
            - all non-technical.
          </li>
          <li>
            I was made it to the bus right as everyone was leaving and was
            carrying a bunch of 16 oz water bottles with me the whole way. Wow,
            I was new.
          </li>
          <li>
            I didn't know about sequencing the first few rappels, I didn't know
            anything about ascending. I was still using my climbing harness and
            ATC.
          </li>
          <li>
            I didn't know that Zion was Zion and not{' '}
            <GlossaryLink>Zions</GlossaryLink>.
          </li>
        </ul>
        <p>
          It was obvious right from the start that I didn't know what I was
          doing but they were all very kind and encouraged me to take a BSA
          climbing instructor course with them. I agreed and signed up for the
          next available one a few months later.
        </p>
        <p>
          It was at this course that I finally learned about everything that
          could possible go wrong in canyoneering. Someone could get stuck on
          rope, lose their rappel device, core shot the rope, break an ankle,
          fall off a cliff, have a rock dropped on their head, burn their hand
          rappelling. All sorts of things can go wrong and if we aren't prepared
          for them it will result in spending a night in the canyon waiting to
          be rescued or doing something really dangerous because we aren't
          prepared to fix an easy mistake.
        </p>
        <p>
          From that point I took classes, went to workshops and practice
          sessions, and eventually became an ACA Canyon Leader. I now
          occasionally host practice sessions and help teach when I have time.
        </p>
        <p>
          I was lucky enough to learn about these potential problems early and
          get adequate training and I would encourage you to do so as well.
          There are classes being taught all over the place and you owe it to
          yourself to be a safe canyoneer.
        </p>
      </Layout>
    );
  }
}

export default withFrontMatter('2012-04-28-my-story')(Blog_2012_04_28_My_Story);
